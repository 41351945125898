import React from 'react';
import PriceList from '../components/PriceList';
import OffersList from '../components/OffersList';
import UserDlcsList from '../components/UserDlcsList';

interface DashboardProps {
  isLoggedIn: boolean;
}

const dashboard: React.FC<DashboardProps> = ({ isLoggedIn }) => {
  return (
    <div className='mt-8'>
      {isLoggedIn ? (
        <>
          <h2 className='text-green-400 text-3xl mx-5'>
            Welcome to Your own user dashboard!
          </h2>
          <UserDlcsList />
        </>
      ) : (
        <>
          <h2 className='text-red-400 text-3xl mx-5'>
            Please log in to access Your own user dashboard!
          </h2>
        </>
      )}

      <OffersList />

      <PriceList />
    </div>
  );
};

export default dashboard;
