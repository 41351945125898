import React, { useState, useEffect } from 'react';
import { getOffers } from '../api/offers';
import Offer from './Offer';

interface OfferData {
  dlc_id: string;
  temporary_contract_id: string;
  ini_role: string;
  oracle_id: string;
  status: string;
  interest: number;
  created_at: number;
  updated_at: number;
  timeout_at: number | null;
}

const OffersList: React.FC = () => {
  const [offers, setOffers] = useState<OfferData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await getOffers();
        setOffers(response.payload);
      } catch (error) {
        setError('Failed to fetch offers');
      } finally {
        setLoading(false);
      }
    };
    fetchOffers();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const borrowOffers = offers.filter((offer) => offer.ini_role === 'borrow');
  const lendOffers = offers.filter((offer) => offer.ini_role === 'lend');

  return (
    <div className='p-4'>
      <h3 className='text-2xl mb-4'>OFFERS</h3>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
        <Offer
          title='Borrow Offers'
          offers={borrowOffers}
          textColor='text-green-400'
        />
        <Offer
          title='Lend Offers'
          offers={lendOffers}
          textColor='text-purple-400'
        />
      </div>
    </div>
  );
};

export default OffersList;
