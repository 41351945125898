import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import ExplainerVideo from './components/ExplainerVideo';
import FeaturesList from './components/FeaturesList';
import Footer from './components/Footer';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { getToken, removeToken } from './api/token';

// the main App component that renders the Header, FeatureButtons, ExplainerVideo, FeaturesList, and Footer components
// defining the layout of the application
// default export of the App component to be used in the index.tsx file for rendering the application in the browser
// default styles are applied to the App component using Tailwind CSS classes
const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);

  useEffect(() => {
    const token = getToken();
    if (token) {
      setIsLoggedIn(true);
      // Set user email if you have it stored
      // setUserEmail(storedEmail);
    }
  }, []);

  const handleLogout = useCallback(() => {
    removeToken();
    setIsLoggedIn(false);
    setUserEmail(null);
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(handleLogout, 120000); // 2 perc inaktivitás után kijelentkezés
    };

    const handleActivity = () => {
      resetTimeout();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    resetTimeout();

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [handleLogout]);

  return (
    <Router>
      <div className='min-h-screen grid grid-rows gap-8 bg-black text-gray-200'>
        <div className='px-6 '>
          <Header
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            userEmail={userEmail}
          />
          <Routes>
            <Route
              path='/loginpage'
              element={
                <Login
                  setIsLoggedIn={setIsLoggedIn}
                  setUserEmail={setUserEmail}
                />
              }
            />
            <Route
              path='/'
              element={<Dashboard isLoggedIn={isLoggedIn} />}
            />
          </Routes>
        </div>
        <div className='px-6'>
          <div className='flex flex-col md:flex-row md:space-x-8 w-full px-4 items-center justify-center'>
            <div className='w-full md:w-1/2 flex justify-center md:justify-end'>
              <ExplainerVideo />
            </div>
            <div className='w-full md:w-1/2'>
              <FeaturesList />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Router>
  );
};

export default App;
