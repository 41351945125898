import React, { useState, useEffect } from 'react';
import { getPrice } from '../api/price';

const Price: React.FC = () => {
  const [price, setPrice] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPrice = async () => {
    try {
      const price = await getPrice();
      setPrice(price);
      setError(null);
    } catch (error) {
      setError('Failed to fetch price');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrice(); // Initial fetch
    const interval = setInterval(fetchPrice, 120000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>{error}</span>;
  }

  return <span>{price !== null ? price.toFixed(2) : 'N/A'}</span>;
};

export default Price;
