// import exp from 'constants';
import React from 'react';

interface InputProps {
  type: string;
  name: string;
  value: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  required?: boolean;
  autoComplete?: string;
}

const Input: React.FC<InputProps> = ({
  type,
  name,
  value,
  placeholder,
  onChange,
  className = 'w-full p-2 border border-gray-300 rounded text-black',
  required = false,
  autoComplete,
}) => (
  <input
    type={type}
    name={name}
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    className={className}
    required={required}
    autoComplete={autoComplete}
  />
);

export default Input;
