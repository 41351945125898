import React from 'react';

export interface ButtonProps {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean; // Add loading to the props
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  disabled,
  loading = false, // Default value for loading
}) => (
  <button
    className={`p-2 rounded-lg shadow-md w-full text-xl ${
      loading
        ? 'bg-gray-400 cursor-not-allowed'
        : 'bg-gray-500 hover:bg-gray-600'
    }`}
    onClick={onClick}
    disabled={disabled}
    aria-busy={loading ? 'true' : 'false'}
  >
    {label}
  </button>
);

export default Button;
