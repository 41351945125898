import React, { useState, useEffect } from 'react';
import { getUserDlcs } from '../api/userDlcs';
import Dlc from './Dlc';

interface DlcData {
  dlc_id: string;
  tmp_cntr_id: string;
  ini_email: string;
  ini_pubkey: string;
  acc_email: string;
  acc_pubkey: string | null;
  orcl_id: string;
  cntr_terms: {
    payouts: {
      win: number;
      loss: number;
    };
    fee: number;
  };
  status: string;
  interest: number;
  ini_signatures: Record<string, any>;
  acc_signatures: Record<string, any>;
  created_at: string;
}

const UserDlcsList: React.FC = () => {
  const [dlcs, setDlcs] = useState<DlcData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDlcs = async () => {
      try {
        const response = await getUserDlcs();
        setDlcs(response.payload);
      } catch (error) {
        setError('Failed to fetch DLCs');
      } finally {
        setLoading(false);
      }
    };
    fetchDlcs();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const offerDlcs = dlcs.filter((dlc) => dlc.status === 'offer');
  const acceptedDlcs = dlcs.filter((dlc) => dlc.status === 'accepted');
  const signedDlcs = dlcs.filter((dlc) => dlc.status === 'signed');

  return (
    <div className='p-4'>
      <h3 className='text-2xl mb-4'>User DLCs</h3>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
        <Dlc
          title='Offer DLCs'
          dlcs={offerDlcs}
          textColor='text-blue-400'
        />
        <Dlc
          title='Accepted DLCs'
          dlcs={acceptedDlcs}
          textColor='text-green-400'
        />
        <Dlc
          title='Signed DLCs'
          dlcs={signedDlcs}
          textColor='text-red-400'
        />
      </div>
    </div>
  );
};

export default UserDlcsList;
