import axios from 'axios';
import qs from 'qs';
import { saveToken } from './token';

const API_URL = process.env.REACT_APP_API_URL;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '';
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET || '';

export const getAuthToken = async (
  email: string,
  password: string,
  timeout: number = 120000 // Alapértelmezett 2 perces időkorlát
): Promise<string> => {
  try {
    const data = qs.stringify({
      grant_type: 'password',
      username: email, // Use 'username' instead of 'email'
      password,
      scope: '',
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    });

    const response = await axios.post(`${API_URL}/app/auth/token`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      timeout, // Időkorlát beállítása
    });

    const token = response.data.access_token;
    saveToken(token); // Token mentése
    if (process.env.NODE_ENV === 'development') {
      console.log('Token successfully retrieved');
    }
    return token;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.code === 'ECONNABORTED') {
        alert('The request took too long - please try again later.');
      } else if (error.response) {
        console.error(
          'Token request failed with status:',
          error.response.status
        );
        if (process.env.NODE_ENV === 'development') {
          console.error(
            'Error details:',
            error.response.data.message || 'No message available'
          );
        }
      } else if (error.request) {
        console.error('No response received during token request.');
      }
    } else {
      console.error(
        'Unexpected error:',
        error instanceof Error ? error.message : error
      );
    }
    throw new Error('Authentication failed. Please check your credentials.');
  }
};
