import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

interface Offer {
  dlc_id: string;
  temporary_contract_id: string;
  ini_role: string;
  oracle_id: string;
  status: string;
  interest: number;
  created_at: number;
  updated_at: number;
  timeout_at: number | null;
}

interface OffersResponse {
  timestamp: number;
  payload: Offer[];
  message: string;
}

export const getOffers = async (
  ini_role: string = 'both'
): Promise<OffersResponse> => {
  const response = await axios.get(`${API_URL}/app/dlcp/v0/offers`, {
    params: { ini_role },
    headers: {
      Accept: 'application/json',
    },
  });
  if (response.data && Array.isArray(response.data.payload)) {
    return response.data;
  } else {
    throw new Error('Invalid response format');
  }
};