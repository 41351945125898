import React from 'react';

interface OfferData {
  dlc_id: string;
  temporary_contract_id: string;
  ini_role: string;
  oracle_id: string;
  status: string;
  interest: number;
  created_at: number;
  updated_at: number;
  timeout_at: number | null;
}

interface OfferProps {
  title: string;
  offers: OfferData[];
  textColor: string;
}

const Offer: React.FC<OfferProps> = ({ title, offers, textColor }) => {
  return (
    <div>
      <h4 className={`text-xl font-semibold ${textColor} mb-4`}>{title}</h4>
      {offers.map((offer, index) => (
        <div
          key={index}
          className='border rounded-lg p-4 shadow-md'
        >
          <p className={`${textColor}`}>
            <strong>DLC ID:</strong> {offer.dlc_id}
          </p>
          <p className={`${textColor}`}>
            <strong>Temporary Contract ID:</strong>{' '}
            {offer.temporary_contract_id}
          </p>
          <p className={`${textColor}`}>
            <strong>Role:</strong> {offer.ini_role}
          </p>
          <p className={`${textColor}`}>
            <strong>Oracle ID:</strong> {offer.oracle_id}
          </p>
          <p className={`${textColor}`}>
            <strong>Status:</strong> {offer.status}
          </p>
          <p className={`${textColor}`}>
            <strong>Interest:</strong> {offer.interest}%
          </p>
          <p className={`${textColor}`}>
            <strong>Created At:</strong>{' '}
            {new Date(offer.created_at * 1000).toLocaleString()}
          </p>
          <p className={`${textColor}`}>
            <strong>Updated At:</strong>{' '}
            {new Date(offer.updated_at * 1000).toLocaleString()}
          </p>
          {offer.timeout_at && (
            <p className={`${textColor}`}>
              <strong>Timeout At:</strong>{' '}
              {new Date(offer.timeout_at * 1000).toLocaleString()}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default Offer;
