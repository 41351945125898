import React from 'react';

interface DropdownProps {
  options: string[];
  selected: string;
  onSelect: (option: string) => void;
  placeholder?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  selected,
  onSelect,
  placeholder,
}) => {
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(event.target.value);
    onSelect(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSelectElement>) => {
    if (event.key === 'Enter') {
      onSelect(selectedValue);
    }
  };

  return (
    <select
      value={selectedValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      className='p-2 rounded-lg shadow-md w-full text-xl bg-gray-500 hover:bg-gray-600'
    >
      {placeholder && <option value=''>{placeholder}</option>}
      {options.map((option) => (
        <option
          key={option}
          value={option}
        >
          {option}
        </option>
      ))}
    </select>
  );
};

export default Dropdown;
