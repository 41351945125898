import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/common/Button';
import { getAuthToken } from '../api/auth';
import { saveToken, getToken } from '../api/token';
import axios from 'axios';
import Input from '../components/common/Input';

interface LoginProps {
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setUserEmail: (email: string | null) => void;
}

const Login: React.FC<LoginProps> = ({ setIsLoggedIn, setUserEmail }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleLoginClick = async () => {
    if (!email || !password) {
      alert('Email and password are required');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      let token = getToken();
      if (!token) {
        token = await getAuthToken(email, password, 120000); // 2 perces időkorlát beállítása
        saveToken(token);
      }
      setIsLoggedIn(true); // Set login state to true on successful login
      setUserEmail(email); // Set user email in state
      navigate('/'); // Redirect to Dashboard
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.code === 'ECONNABORTED') {
          alert('The request took too long - please try again later.');
        } else if (error.response) {
          console.error(
            'API request failed with status:',
            error.response.status
          );
          console.error(
            'Error details:',
            error.response.data.message || 'No message available'
          );
          if (error.response.status === 422) {
            setError('Validation error: Please check your email and password.');
          } else {
            setError('An error occurred. Please try again.');
          }
        } else if (error.request) {
          console.error('No response received during API request.');
          setError('No response received. Please try again.');
        }
      } else {
        console.error(
          'Unexpected error:',
          error instanceof Error ? error.message : error
        );
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex flex-col space-y-4 my-4'>
      <p className='text-sm text-center mt-8 text-gray-400'>Please log in!</p>
      <form
        onSubmit={(e) => e.preventDefault()}
        className='flex flex-col space-y-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mx-auto'
      >
        <Input
          type='email'
          name='email'
          value={email}
          placeholder='Email address'
          onChange={(e) => setEmail(e.target.value)}
          required
          autoComplete='email'
        />
        <Input
          type='password'
          name='password'
          value={password}
          placeholder='Password'
          onChange={(e) => setPassword(e.target.value)}
          required
          autoComplete='password'
        />
        <div className='flex flex-row justify-between space-x-4'>
          <div className='w-full'>
            <Button
              label='Login'
              onClick={handleLoginClick}
              disabled={loading}
            />
          </div>
        </div>
        {loading && (
          <p className='text-center text-gray-600'>Processing request...</p>
        )}
        {error && <p className='text-center text-red-600'>{error}</p>}
      </form>
    </div>
  );
};

export default Login;
