import React from 'react';

const ExplainerVideo: React.FC = () => (
  <div className='my-8'>
    <div className='w-72 h-40 bg-gray-500 flex items-center justify-center rounded-lg shadow-lg'>
      Explainer Video
    </div>
  </div>
);

export default ExplainerVideo;
