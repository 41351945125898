import axios from 'axios';

const ORACLE_API_URL = process.env.REACT_APP_ORACLE_API_URL;

export const getPrice = async (): Promise<number> => {
  try {
    const response = await axios.get(
      `${ORACLE_API_URL}/api/v0/price/current/USD`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Failed to fetch price:', error);
    throw new Error('Failed to fetch price');
  }
};
