import React, { useState, useEffect } from 'react';
import { getPriceList } from '../api/priceList';
import List from './common/List';

const PriceList: React.FC = () => {
  const [prices, setPrices] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPrices = async () => {
    try {
      const prices = await getPriceList();
      setPrices(prices);
      setError(null);
    } catch (error) {
      setError('Failed to fetch price list');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrices(); // Initial fetch
    const interval = setInterval(fetchPrices, 120000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className='p-4 mt-8'>
      <h3 className='text-2xl py-4'>PRICES</h3>
      <List
        items={Object.entries(prices)}
        renderItem={([currency, value], index) => (
          <div
            key={index}
            className='border rounded-lg p-4 mb-4 shadow-md'
          >
            <p className='text-amber-500'>
              {currency}: <strong>{value.toFixed(2)}</strong>
            </p>
          </div>
        )}
      />
    </div>
  );
};

export default PriceList;
